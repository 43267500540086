import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FormType } from '../../../../models/FormTypes';
import ConditionsProperties from '../../../form-builder/ConditionsProperties';
import { EditorProps } from '../../../form-builder/FormBuilderTypes';
import PreviewProperties from '../../../form-builder/PreviewProperties';
import RiskProperties from '../../../form-builder/RiskProperties';
import Checkbox from '../../../shared/form-control/Checkbox';
import ActionEditorBase from '../ActionEditorBase';

const YesNoEditor: FC<EditorProps> = (props) => {
  const { action, patchAction, patchData, form } = props;
  const { enableNA } = action?.data || {};
  const { t } = useTranslation('form-builder');

  const onNAChange = useCallback(
    (value: boolean) => {
      patchData({ enableNA: value });
    },
    [patchData],
  );

  return (
    <div data-cy="yes-no-editor">
      <ActionEditorBase {...props}>
        <Checkbox value={enableNA ?? false} onChange={onNAChange} label={t('action-properties.yesno.enableNA')} className="my-4" />
      </ActionEditorBase>

      <ConditionsProperties {...props} />

      <PreviewProperties {...props} enableDocumentNumbering />

      {(form.type === FormType.Asset || form.type == FormType.SubForm || form.type == FormType.SubFormWithApproval) && (
        <RiskProperties action={action} patchAction={patchAction} form={form} />
      )}
    </div>
  );
};

export default YesNoEditor;
